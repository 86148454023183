.password-request-page {
  padding: 30px;
  .request-form__item {
    margin: 10px 0px;
    &.label {
      font-weight: bold;
    }
  }
}

.password-sent-page {
  padding: 30px;
  .sent-info__text {
    margin: 10px 0px;
    &.email-address {
      font-weight: bold;
    }
  }
}

.password-reset-page {
  padding: 20px 5px;
  .password-reset__item {
    margin: 10px;
  }
}

#past_purchases {
  .past-purchases__shopping {
    margin: 0px;
    text-align: center;
    width: 100%;
  }
}

#registration {
  #profile_preferences {
    .optional-info {
      .optional-info__fieldset {
        .when_is_your_birthday {
          margin-left: 10px;
        }
      }
    }
    h2 {
      border-bottom: 1px solid #000000;
      clear: both;
      margin: 0 0 20px;
      padding-bottom: 14px;
      text-indent: 20px;
      font-family: 'Brandon Text Bold';
      font-size: 12px;
      font-weight: normal;
      letter-spacing: 0.2em;
      line-height: 21px;
      text-transform: uppercase;
    }
    .email_promotions {
      margin: 0 20px;
      a {
        color: #{$color-bundle-gray};
      }
      .sms-wrapper {
        margin-top: 10px;
      }
    }
    .profile_tip {
      a {
        color: #{$color-bundle-gray};
      }
    }
    input.big-button {
      margin: 10px 0 15px 20px;
    }
  }
}

.profile-page {
  .profile-page__content {
    .breadcrumb {
      padding: 10px 0px 10px 0px;
    }
    .social-info {
      a {
        color: #{$color-bundle-gray};
      }
    }
    .content-block {
      overflow: visible;
    }
  }
  .profile-page__head {
    h1 {
      .inner {
        background: #ffffff;
        display: inline-block;
        position: relative;
        padding: 0 7px 0 9px;
      }
    }
  }
}

#myaccount-wrapper {
  .breadcrumb {
    padding: 10px 0px 10px 0px;
  }
}

#add_address {
  #myaccount-wrapper {
    .qas {
      .address-form__item {
        padding: 10px 0;
      }
      .row {
        select {
          width: 100%;
          word-wrap: break-word;
        }
      }
    }
    .default_ship_container {
      span {
        &.label-content {
          display: block;
          margin: -20px 0 0 20px;
        }
      }
    }
  }
}

.order-details-page {
  &__content {
    .cart-item {
      &__total {
        font-size: 14px;
        line-height: 36px;
        padding-left: 10px;
      }
    }
  }
}

#choose-address {
  .address_select {
    width: 100%;
  }
  .address_select {
    width: 250px;
    word-wrap: break-word;
  }
}

.payment-info-page {
  .payment_book_container {
    margin-left: 10px;
  }
}

#payment_edit {
  margin-left: 20px;
}

.add-payment-page {
  #myaccount-wrapper {
    .payment_form {
      margin: 0px 15px 0px 15px;
      .payment_address {
        border-bottom: 1px solid #ccc;
        padding-bottom: 15px;
        .required-message {
          p {
            margin-bottom: 15px;
          }
        }
        h2 {
          margin-bottom: 15px;
        }
        p {
          margin: 0px;
        }
      }
      .payment_method {
        margin-bottom: 15px;
        .payment-form__subheading {
          border-bottom: 1px solid #776a6a;
        }
        .card_number_field {
          width: 100%;
        }
        .expiration_date_container {
          margin-bottom: 33px;
          .date_fields {
            a,
            select {
              float: left;
              width: 46%;
              margin-right: 10px;
            }
          }
        }
        h2 {
          margin: 15px 0;
        }
      }
    }
  }
}

#myaccount-wrapper {
  margin-bottom: 20px;
  .payment-info__header-container {
    padding: 0 15px;
  }
  .submit input {
    background-color: #ff4661;
  }
  .payment_method {
    margin-top: 20px;
    #billing_payment_form {
      .form_element {
        label {
          display: inline-block;
          margin-bottom: 13px;
          font-weight: bold;
        }
      }
      .default_payment_container {
        label {
          span {
            font-weight: normal;
          }
        }
      }
    }
  }
  .align-center {
    margin: 0 10px;
  }
  .payment-form {
    &__header {
      font-family: 'Brandon Text Bold';
      font-weight: normal;
      font-size: 17px;
      text-transform: uppercase;
      text-indent: 20px;
      margin: 4px 0 17px 0;
      padding-bottom: 16px;
      border-bottom: 2px solid #000000;
    }
    &__subheading {
      font-weight: bold;
      margin-bottom: 15px;
      padding-bottom: 10px;
    }
    &__item {
      .cv_number_field {
        width: 100%;
      }
    }
  }
  .payment-book__button {
    border-bottom: 1px solid #ccc;
  }
  h2.payment-info__header {
    font-family: 'Brandon Text Bold';
    font-weight: normal;
    font-size: 17px;
    text-transform: uppercase;
    text-indent: 20px;
    margin: 4px 0 17px 0;
    padding-bottom: 16px;
    border-bottom: 2px solid #000000;
  }
  .credit_card {
    margin-top: 15px;
  }
  .payment_book_container {
    margin: 0px 15px 0px 15px;
    a.add_payment_link {
      margin-bottom: 15px;
      background-color: #ff4661;
    }
    .column {
      margin-top: 10px;
      .credit_card {
        .billing_address {
          margin-top: 15px;
          h3 {
            margin-bottom: 10px;
            font-weight: bold;
          }
        }
      }
      .controls {
        margin-bottom: 15px;
        padding-top: 10px;
        a {
          padding-right: 10px;
        }
      }
    }
  }
}

#giftcard_balance {
  h1.profile-page__header {
    margin-bottom: 5px !important;
    font-size: 14px !important;
    &:before {
      background: none !important;
    }
  }
  .profile-info__text {
    margin-bottom: 10px;
  }
  .profile-info__label {
    font-size: 13px !important;
    width: 100%;
    float: left;
    padding-left: 10px;
  }
  #big-button {
    margin: 10px;
    float: left;
  }
  .profile-info__required {
    display: inline;
  }
  #required_container {
    float: right;
    margin: 7px 10px 0px 0px;
    font-size: 10px;
  }
  #balance_container {
    display: block;
    float: left;
    width: 100%;
    margin-top: 20px;
    background: #f8f8f8;
    text-align: center;
    #balance {
      font-size: 36px;
      text-align: center;
      float: left;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 40px;
      font-weight: bold;
    }
    h1.profile-page__header {
      margin-top: 20px;
      background: #f8f8f8 !important;
      &:before {
        background: #000000 !important;
      }
      .inner {
        background: #f8f8f8 !important;
      }
    }
  }
}

#pro-attributes-form fieldset {
  padding: 0px;
}

.pending-pro_info_application div,
.pending-pro_info fieldset,
.pro-attributes fieldset,
.fs.email_promotions,
.pro_attributes_form #pro-attributes-form fieldset {
  padding: 0px 20px;
}

.touch select {
  background-position-y: 10px !important;
}

.pro_level_criteria ul {
  list-style-type: disc;
  padding-left: 20px;
  padding-bottom: 20px;
}

.user-info__item {
  padding-top: 5px;
  padding-bottom: 5px;
}

.user-info__item.user_id div,
.user-info__item.full_name div,
.user-info__item.birthday div,
.user-info__item.phone div,
.user-info__item.email div,
.user-info__item.pro_membership_level div {
  float: left;
  padding-right: 5px;
}

.pending-pro_registration_steps ul {
  list-style-type: disc;
  padding-left: 20px;
}

.pending-pro_level.pro-criteria ul {
  list-style-type: disc;
}

.pro-criteria-level,
.pending-pro_mail_processing {
  padding: 0 !important;
}

.pro-attributes-options {
  padding-bottom: 10px;
}

.registration_submit {
  margin-left: 20px;
}

.pending-pro_buttons {
  display: none;
}

.artist_form_container {
  margin-left: 0px !important;
}

#profile_preferences .email_promotions {
  margin-left: 0px !important;
}

#profile_preferences .pro-membership__table td {
  padding-left: 20px;
  padding-right: 20px;
}

.error_messages.error li {
  margin-right: 20px;
}

.pending-pro__header {
  width: 100%;
}

.registration-pro-page__header:before,
.pending-pro__header:before {
  top: 100%;
}

.order-totals td {
  text-align: right;
}

#form--address--field--PHONE1 {
  width: 84%;
}

.pending-pro .pro-membership-option label {
  width: auto;
}

.artist-picks-page {
  .artist-picks-page__products,
  .artist-picks-page__chats {
    .product-info {
      .product-info__price {
        float: right;
        &.price_per_ml {
          width: 100%;
          text-align: right;
          padding-right: 15px;
          float: right !important;
        }
      }
    }
  }
}

#form--errors--payment_edit.error_messages_display {
  li {
    margin-left: 0px;
  }
}

#form--errors--payment_add.error_messages_display {
  li {
    margin-left: 15px;
  }
}
/* Password GDPR validation */
.password-field {
  position: relative;
  &__info {
    .password-field__rules {
      column-count: 2;
      li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 3px 0;
        color: $color-black;
        transition: 0.2s;
        white-space: nowrap;
        line-height: 1.5;
        &.pass {
          color: $color-green;
          &::before {
            color: $color-green;
            content: '\2713';
            text-shadow: 0 0 8px rgba($color-black, 0.5);
          }
        }
        &::before {
          content: '\2715';
          display: inline-block;
          color: $color-red;
          font-size: 1em;
          line-height: 0;
          margin: 0 6px 0 0;
          transition: 0.2s;
        }
      }
    }
  }
}

.sign-in-page {
  .account {
    &__return-user {
      .return-user__link {
        color: #{$color-bundle-gray};
      }
    }
    &__new-account {
      .privacy_policy {
        a {
          color: #{$color-bundle-gray};
        }
      }
    }
  }
  .social-login {
    .fb-disclaimer {
      a {
        color: #{$color-bundle-gray};
      }
    }
  }
}

.registration-pro-page__content {
  .profile-info {
    .selectbox {
      &:hover {
        color: $color-cta-hover;
      }
    }
  }
  .pro-member-info {
    #pro-level-form {
      a {
        color: $color-gray-link;
      }
    }
  }
  .newsletter-info {
    input[type='submit'] {
      margin-#{$ldirection}: 40%;
    }
  }
}
