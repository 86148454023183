/**
 * @file uk_mobile.scss
 *
 * \brief Pull all parts of the US-MOBILE theme together into one file
 */

// Virtual Appointment Enabling variable
$virtual-appointment: true !default;

@import 'overrides/overrides';
@import '../../bb_base/scss/bobbibrown-base';
@import 'global/variable';
// MPP styles
@import 'sections/products/mpp';

// SPP styles
@import 'sections/products/mobile_spp';

// SMACSS/Components/account
@import 'sections/mobile/account/account';

// SMACSS/Components/checkout
@import 'sections/checkout/checkout';

// SMACSS/store_locator_mobile
@import 'components/store_locator';
@import '../../bb_base/scss/components/store_locator_mobile';
@import 'components/engraving';

//BB PRO
@import 'components/bobbi-pro.scss';

// Click and Collect
@import 'sections/checkout/clickcollect';

// Remove Sticky Nav
@import 'sections/mobile/nav_override';

// Sample Grid
@import 'components/products/sample_grid';

//Email Sigm Up UK Overrides
@import 'components/email-sms-signup';
@import 'components/email-sms-thank-you';

//Build Your Brow Kit
@import 'sections/landing_pages/bybk';

//Black Friday Sign Up Page
@import 'sections/landing_pages/bf_signup';

//Promotions Pages
@import 'sections/landing_pages/promotions_signup';

//Privacy Page
@import 'components/bt/bt';

// One Trust Cookie settings
@import 'components/cookie_setting_ot';

// GDPR update
@import 'components/_social_login';

// Book Appointment styles.
@import 'components/appointment_booking';

#bt_notification {
  div.bt-content {
    right: 0 !important;
    border-color: #ccc !important;
    border-width: 1px !important;
    a {
      text-decoration: none !important;
      color: #ff4661 !important;
    }
    .bt-find-more {
      line-height: 2em;
      font-size: 14px;
      border-bottom: 1px solid #ff4661;
    }
    h6 {
      font-size: 1.17em;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .bt-pp-section {
      margin-bottom: 20px;
    }
    .button {
      margin-top: 10px;
      display: block;
      color: #fff !important;
    }
  }
  div.bt-content-mobile {
    width: 100% !important;
  }
}

@import 'sections/mobile/all';

.tiny-waitlist-overlay {
  .email_label {
    margin-bottom: 5px;
  }
  .email_input {
    display: inline-block;
    input[type='text'] {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .waitlist-header {
    display: block;
    font-weight: bold;
    padding-bottom: 10px;
  }
  h2 {
    margin: 0 0 15px 0;
    text-align: left;
  }
  p.waitlist_message {
    margin-bottom: 10px;
  }
  .error_messages.error li {
    margin-left: 0px;
  }
  .field {
    border-top: 1px solid gray;
    padding-top: 10px;
    .align-r {
      display: inline-block;
    }
  }
}

.waitlist_thankyou_message {
  font-family: $brandon-text-bold;
  margin: 90px 25px;
  line-height: 1.45em;
}

.waitlist-iframe-wrapper {
  height: 95%;
  width: 100%;
}

.touch select {
  background-position: 95% 100%;
}

.bobbis-lessons__footer .bobbis-lessons__footer--right .link {
  border: none;
  padding: 0px;
}

.QAS_SELECT {
  width: 100%;
}
/* my order sample size qty override */
// qty
.cart-item {
  &__qty {
    // __qty-label
    &-label {
      .sample & {
        display: none;
      }
    }
  } // /qty
}

.field-collection-slides {
  div.field-menu {
    div.menu {
      overflow: hidden;
    }
    ul.menu__list--lvl-1 {
      width: 100%;
      li {
        width: 50%;
        padding-left: 3px;
        padding-right: 3px;
        font-size: 12px;
      }
    }
  }
}

article.gift_guide {
  div.mpp__gift_guide {
    div.product-collection-tabs {
      ul li.tab-item a {
        padding-left: 3px;
        padding-right: 3px;
        font-size: 12px;
      }
    }
  }
}
/* power review scss */

.product-full__review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        text-transform: initial;
      }
    }
  }
}

#power_review_container {
  .p-w-r {
    .form-group {
      input {
        font-family: $brandon-text-medium;
      }
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review {
      .pr-rd-description {
        .pr-rd-author-nickname {
          font-size: 14px;
          font-family: $brandon-text-bold;
        }
      }
    }
    #pr-image-display {
      display: none;
    }
  }
}

.spp {
  .product {
    .product__title {
      .product__flag {
        display: inline-block;
      }
    }
  }
}

//Loyalty
@import "sections/loyalty";
