// Mobile down
@include breakpoint(max-width $tablet) {
  // On mobile dont show these pages
  #bp-artist {
    display: none;
  }
  // Hide 2nd and 4th (Apply and Artist Page) accordion content on mobile
  .bobbi-pro {
    .resp-tabs-container > * {
      &[aria-controls='tab_item-3'] {
        display: block;
      }
    }
    &__tabs {
      .resp-tabs-container {
        text-align: center;
      }
    }
    &__artist {
      padding-bottom: 15px;
      &-bio {
        margin-right: auto;
        &__content-section__products {
          margin: 0 auto 20px;
          float: none;
          border: none;
          &-image {
            margin: 0 auto;
          }
        }
        @include breakpoint(max-width 480px) {
          display: block;
        }
      }
    }
  }
}

.block-nodeblock-2216 {
  padding-bottom: 30px;
}
