@media only screen and (orientation: portrait) {
  .section-customer-service-contact-us {
    #contact-about-content {
      .customer-service-contact-form__category {
        padding: 1em 14px;
        letter-spacing: 1px;
      }
    }
  }
  #viewcart-panel {
    .viewcart-panel__content {
      .cart-items {
        .cart-item__desc-content {
          #cart {
            .selectbox {
              padding: 0 4px;
            }
          }
        }
      }
    }
  }
  .checkout-confirmation-page {
    .sign-in-confirmation-panel {
      .sign-in-confirmation-panel__content {
        #completed_transaction_signin {
          .password {
            margin-bottom: 10px;
          }
          .submit {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}

.confirmation-panel__confirm-text--donation {
  .checkout__subtitle {
    border-bottom: 0px;
    margin-bottom: 0px;
  }
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f0f0f0;
}

.spp-mobile-page {
  .responsive-tabs--how_to_use-wrap {
    .responsive-tabs.responsive-tabs--how_to_use {
      .resp-arrow {
        position: relative;
        float: right;
        top: 1px;
      }
    }
  }
}

#search {
  .pagination-container {
    .pagination {
      .paging-select {
        select {
          min-width: 150px;
        }
      }
      a {
        margin-right: 10px;
      }
      a:last-child {
        margin-right: 0px;
      }
    }
  }
}

#search-results {
  #refinements {
    display: none;
    line-height: 1.231;
    text-align: center;
    div.accordion-header {
      background: url('/images/mobile/icons/select-shade-arrows.png') no-repeat scroll 96% 7px
        #f8f8f8;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 1px solid #cccccc;
      text-transform: capitalize;
      font-weight: bold;
      padding: 5px 0px 5px 0px;
    }
    .accordion-content {
      padding: 4px 4px 10px;
      background-color: #f8f8f8;
      float: right;
      width: 100%;
      .results {
        select {
          width: 45%;
          margin: 2px 0 2px 8px;
          color: #999999;
          font-family: arial;
          font-size: 12px;
          height: 25px;
          letter-spacing: 0.01em;
          option {
            width: 80%;
          }
        }
      }
      #breadcrumbs {
        font-size: 12px;
        font-family: arial;
        letter-spacing: 0.01em;
        .breadcrumb {
          float: left;
          padding-left: 14px;
          position: relative;
          width: 150px;
          margin: 2px 0 2px 8px;
        }
        .breadcrumb a {
          color: #444444;
          display: block;
          text-decoration: none;
        }
        .breadcrumb .close_x {
          color: black;
          font-weight: bold;
          left: 0;
          position: absolute;
          top: 1px;
        }
      }
    }
  }
}

.checkout {
  .checkout__content {
    .sign-in-panel {
      .panel-group {
        #new-account {
          #checkout_signin_guest_user {
            #checkout_signin_guest_user-submit {
              margin-bottom: 15px;
            }
          }
        }
        #return-user {
          #checkout_signin {
            #checkout_signin-submit {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
    .shipping-panel {
      .shipping-edit-content {
        #checkout_shipping_panel {
          .select-address {
            .address-to-use {
              margin-bottom: 15px;
              margin-top: 15px;
              span.address-to-use-option {
                margin-right: 10px;
                label {
                  font-size: 14px;
                }
              }
            }
          }
          .sub-section {
            .personal-details {
              .title {
                margin-bottom: 5px;
              }
              .first-name {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
    @media only screen and (min-device-width: 320px) and (max-device-width: 420px) {
      .viewcart-buttons-panel {
        .continue-buttons {
          .paypal-checkout {
            width: 100%;
            text-align: center;
            &.disabled {
              margin-bottom: 35px;
            }
            span.paypal-message {
              margin-top: 5px;
            }
          }
          .or {
            width: 100%;
          }
          a.continue-checkout {
            width: 100%;
          }
        }
      }
    }
    @media only screen and (min-device-width: 450px) {
      .viewcart-buttons-panel {
        .continue-buttons {
          a.continue-shopping {
            position: relative;
            width: auto;
          }
        }
      }
    }
    #continue-btn {
      margin-bottom: 15px;
    }
    .viewcart-panel {
      .viewcart-panel__content {
        .cart-items {
          .cart-item {
            .cart-item__desc-container {
              .cart-item__qty {
                //position: absolute;
                //top: 0;
                //right: 0;
                //width: auto;

                .qty-label {
                  display: none;
                }
              }
              .cart-item__desc {
                width: 60%;
              }
              .cart-item__total {
                width: 100%;
                margin: 0px;
                padding: 0px;
                .cart-item__remove-form {
                  float: left;
                }
              }
            }
          }
        }
      }
    }
  }
  .checkout__sidebar {
    #need-help-panel {
      margin-bottom: 15px;
    }
    #viewcart-panel {
      .viewcart-panel__content {
        .cart-items {
          .cart-item {
            .cart-item__desc-container {
              .qty {
                width: 25%;
                margin-bottom: 20px;
              }
              .cart-item__desc {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 640px) {
    .viewcart-panel {
      .viewcart-panel__content {
        .cart-items {
          .cart-item {
            .cart-item__desc-container {
              .total.cart-item__total {
                display: block;
                color: #fff;
              }
              .cart-item__price {
                position: relative;
                top: 7px;
              }
              .desc.cart-item__desc {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.customer-service-section {
  border-bottom: 1px solid #ccc;
  .customer-service-landing-block__link-container {
    text-align: center;
    display: block;
  }
  .customer-service-landing-block__link {
    width: 100px;
    position: inherit;
    border-width: 3px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin: 0 auto;
  }
  .customer-service-landing-block {
    a.customer-service-landing-block__link {
      bottom: 0px;
    }
  }
  #landing-email {
    border-bottom: 1px solid #ccc;
  }
  .customer-service-landing-block-call {
    display: block !important;
    background: none !important;
    margin: 0;
    padding: 20px;
    text-align: center;
    border-top: 1px solid #ccc;
    clear: both;
    h2 {
      background-image: none;
      border-top: 0px;
    }
    span {
      font-size: 3em;
    }
    .customer-service-landing-block__subheader {
      font-weight: bold;
      margin-bottom: 6px;
    }
    .customer-service-landing-block__phone-number {
      color: #ff4661;
      letter-spacing: 5px;
    }
    .customer-service-landing-block__desc {
      margin-bottom: 15px;
      .customer-service-landing-block__phone-no {
        color: #ff4661;
        font-size: 14px;
      }
    }
  }
}

.offers-details {
  .offers-details-items {
    margin: 20px 0px;
    .offers-details-item__sub-header {
      word-break: break-word;
    }
    .offers-details-item__actions {
      .offers-details-item__link-container {
        width: 100%;
        .offers-details-item__link {
          letter-spacing: 0.15em;
          font-size: 13px;
          margin-right: 5px;
        }
      }
      .offers-details-item__button-container {
        width: 100%;
      }
    }
  }
}

.order-details-page__content {
  section.order-details {
    .trans_detail_item.gift_container {
      margin-top: 20px;
      .gift_info {
        h3 {
          padding-bottom: 10px;
          margin-bottom: 20px;
          border-bottom: 1px solid #bbbbb9;
          font-family: 'Brandon Text Bold';
        }
      }
      .gift_wrap {
        p {
          margin-bottom: 20px;
        }
      }
    }
  }
  section.order-products {
    .cart-item.product {
      .cart-item__qty {
        float: left;
      }
      .cart-item__total {
        float: right;
        margin-top: 5px;
      }
      .cart-item__desc {
        width: 100%;
      }
    }
  }
}

.product__flag {
  display: initial;
}

.checkout {
  .checkout__content {
    #shipmethod-panel {
      #checkout_shipmethod {
        .ship-method-group-label {
          label {
            font-size: 12px;
          }
          &.more_info_label {
            width: 100%;
            padding-right: 0px;
            .more-info {
              float: right;
              color: #{$color-bundle-gray};
            }
          }
        }
      }
    }
    #sign-in-panel {
      .panel-group {
        #new-account,
        #return-user {
          form {
            .form-item {
              .example {
                span em {
                  color: #ff4661;
                }
              }
            }
          }
        }
      }
    }
    #shipping-panel {
      .shipping-edit-content {
        #checkout_shipping_panel {
          section {
            h3.shipping_address_header {
              overflow: hidden;
              position: relative;
              font-size: 12px;
              span.title {
                margin-top: 25px;
                font-weight: bold;
                display: block;
                text-transform: uppercase;
              }
              span.required {
                top: 0px;
                position: absolute;
                left: 0px;
              }
            }
            .select-address {
              .new-address-container {
                .form-item {
                  margin-bottom: 15px;
                  &.title {
                    margin-bottom: 10px;
                  }
                  &.phone-number {
                    input {
                      margin: 0px;
                    }
                  }
                }
                #qas {
                  h3 {
                    margin-bottom: 12px;
                  }
                  .qas-submit-container {
                    input {
                      background: #ff4661;
                    }
                  }
                }
              }
              .local-collection {
                .personal-details {
                  .form-item {
                    margin-bottom: 15px;
                    &.title {
                      width: 50%;
                      select {
                        margin-bottom: 0px;
                      }
                    }
                    &.phone-1 {
                      label {
                        display: none;
                      }
                      input {
                        margin: 0px;
                      }
                    }
                  }
                  .set-collection-point {
                    margin-top: 15px;
                    h3 {
                      margin-bottom: 15px;
                      font-weight: bold;
                      text-transform: uppercase;
                      font-size: 12px;
                    }
                    .form-item {
                      a {
                        background-color: #ff4661;
                      }
                    }
                  }
                }
              }
            }
          }
          .gift-options__content {
            .giftwrap.gift-options {
              .sub-section {
                .gift-msg__head {
                  input {
                    margin-top: 10px;
                  }
                  div {
                    margin-bottom: 10px;
                  }
                }
              }
            }
          }
          .email-sms-promotions {
            .promotions-container {
              p {
                margin-bottom: 15px;
              }
            }
          }
        }
      }
      .adpl {
        .address-to-use {
          label {
            display: inline-block;
          }
        }
      }
    }
    .registration-panel {
      margin-top: 15px;
      #checkout_registration {
        .create_account_content {
          .registration-form-container {
            .email-address {
              span.title {
                display: block;
                font-weight: bold;
                text-transform: uppercase;
              }
              span.example-user-email {
                color: #ff4661;
              }
            }
            .password {
              label {
                margin-bottom: 10px;
                font-weight: bold;
                text-transform: uppercase;
                display: block;
              }
              .password {
                margin-bottom: 10px;
              }
            }
          }
        }
        section.divide {
          p {
            margin-bottom: 15px;
          }
        }
        div.button-wrapper {
          margin-bottom: 15px;
        }
      }
    }
    #review-panel {
      h3.review-panel__title {
        margin-bottom: 10px;
      }
      .review-edit-content {
        h3 {
          overflow: hidden;
          position: relative;
          span.field-are-required {
            top: 0px;
            left: 0px;
            position: absolute;
            text-transform: initial;
          }
          span.title {
            margin-top: 20px;
            display: block;
          }
        }
        .gift-cards {
          margin: 0px;
        }
        #checkout_billing_panel {
          .form-container {
            .payment-type {
              margin-top: 10px;
              span label {
                span {
                  margin-top: 10px;
                  img {
                    width: auto;
                  }
                }
              }
            }
            .sub-section {
              .form-item {
                span {
                  margin-right: 10px;
                }
              }
            }
          }
        }
        .payment_method-container {
          #checkout_payment {
            .form-item {
              label {
                font-weight: bold;
              }
              &.card-cvn {
                overflow: hidden;
                input {
                  margin-bottom: 10px;
                }
              }
              &.checkbox {
                label {
                  font-weight: normal;
                }
              }
            }
          }
        }
        .sub-section.address {
          .view-address {
            .same-as-shipping {
              margin-bottom: 10px;
            }
          }
          .set-address {
            .select-address {
              .address-to-use {
                margin-bottom: 15px;
                span {
                  margin-right: 10px;
                }
              }
              .new-address {
                .form-item {
                  margin-bottom: 10px;
                  &.title {
                    width: 50%;
                    select {
                      margin: 0px;
                    }
                  }
                  &.phone-number {
                    input {
                      margin: 0px;
                    }
                  }
                }
                #qas {
                  h3 {
                    margin-bottom: 10px;
                  }
                  .qas-submit-container {
                    input {
                      background: #ff4661;
                    }
                  }
                }
              }
            }
          }
        }
        #checkout_review {
          section.divide {
            margin-bottom: 15px;
            p {
              margin-bottom: 15px;
            }
          }
        }
        #checkout_saved_payment {
          p {
            margin-bottom: 15px;
          }
          h3 {
            font-weight: bold;
            margin-bottom: 10px;
          }
          .form-item {
            a {
              margin-top: 10px;
            }
          }
        }
      }
      .adpl {
        .expires-date__container {
          .expires-date__month {
            width: 49%;
            float: left;
            margin: 15px 0 0 0;
          }
          .expires-date__year {
            float: right;
            width: 47%;
            margin: 15px 0 0 0;
          }
        }
        .form-item.card-cvn {
          margin: 14% 0 0 0;
          overflow: initial !important;
          label {
            line-height: 1.5em;
            margin-top: calc(-3em - 1px);
          }
        }
        .form-item.card-type,
        .form-item.card-expiry-date {
          .label-content {
            display: none;
          }
        }
        .form-item.gift-card-number,
        .form-item.gift-card-pin {
          margin: 10px 0 10px 0;
        }
      }
    }
  }
  .checkout__sidebar {
    .shipmethod-panel {
      padding: 10px 0px !important;
      .content {
        padding-bottom: 0px;
        #checkout_shipmethod {
          .ship-method-group-label {
            float: none;
            padding-right: 0px;
            label {
              font-size: 12px;
            }
          }
          .ship-method-collection-group {
            margin-top: 0px;
          }
        }
      }
    }
    #offer-code-panel {
      padding: 10px 0px;
      .adpl {
        input[type='text'] {
          height: 2.3em;
        }
      }
    }
  }
}

#footer {
  .site-footer__wrap {
    .site-footer__main {
      .site-footer__column {
        .menu-reference {
          ul.menu__list {
            li {
              width: auto;
              float: none;
            }
          }
        }
      }
    }
    .site-footer-contact {
      background: #fff;
      .site-footer-contact__menu {
        li.site-footer-contact__item--phone {
          border-top: 1px solid #cccccc;
        }
        li.site-footer-contact__item--email {
          border-bottom: 1px solid #cccccc;
        }
        li.site-footer-contact__item--chat {
          .LPMcontainer {
            position: inherit !important;
            .site-footer-contact__link {
              padding: 0;
            }
          }
        }
        li {
          float: left;
          width: 100%;
          a {
            text-align: left;
            span {
              display: inline;
            }
          }
        }
      }
    }
  }
}

#confirmation-page {
  #confirmation-panel {
    .confirmation-panel__actions {
      display: none;
    }
  }
}

.sign-in-panel {
  .panel-group {
    .new-account,
    #return-user {
      .adpl {
        .form-item {
          .return-user-email {
            width: 100%;
          }
          .label {
            line-height: 1.8em !important;
            margin-top: calc(-4em - 1px);
          }
        }
      }
    }
  }
}

.local-collection-overlay {
  position: absolute;
  z-index: 999;
  background-color: #fff;
  a.close-link {
    float: right;
    margin: 4px;
    background: #040a2b;
    color: white;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    padding: 12px 30px;
    height: 44px;
    vertical-align: bottom;
    text-align: center;
  }
  .local-collection-map {
    width: 100%;
    height: 100%;
  }
}

.site-utils {
  &__menu {
    width: 84%;
    margin: 11px auto 0;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    @include breakpoint($medium-up) {
      margin-top: 0;
      position: relative;
      width: auto;
      display: block;
    }
  }
  &__item {
    &--cart {
      right: 6px;
    }
    &--hamburger {
      display: block;
      position: absolute;
      left: 10px;
    }
  }
}

.bobbi-pro {
  &__hero {
    height: 278px;
    background-image: none !important;
    /* background-repeat: no-repeat; */
    /* background-position: bottom right; */
    /* background-size: 50%; */
  }
  @include breakpoint($desktop) {
    background-size: auto 100%;
  }
  &__hero-header {
    font-family: $brandon-text-bold;
    font-size: 1.5em;
    letter-spacing: 5px;
    line-height: 1.2em;
    text-transform: uppercase;
  }
  &__hero-callout {
    text-align: center;
    width: 530px;
    float: left;
    padding: 55px 60px 0 0;
    @include breakpoint($desktop) {
      width: 550px;
      padding: 55px 0 0 50px;
    }
    @include breakpoint($small-down) {
      padding: 2px 0 0;
      width: auto;
    }
  }
  &__hero-header {
    font-family: $brandon-text-bold;
    font-size: 40px;
    letter-spacing: 8px;
    line-height: 1.2em;
    text-transform: uppercase;
  }
  &__hero-subheader {
    font-size: 24px;
    line-height: 1.2em;
  }
  @include breakpoint($mobile) {
    &__hero-header {
      font-size: 36px;
    }
  }
}

.viewcart-panel__content {
  .donations_item {
    .cart-item__desc-container {
      .cart-item__qty {
        margin: 0px;
        padding: 0px;
      }
    }
  }
}

.viewcart-panel.edit .price_sm {
  display: block;
}

.holiday.services {
  .product-collection-tabs {
    .tab-item a {
      padding: 13px 11px;
    }
  }
}

.panel select.error,
.panel select.checkedEmpty,
.panel select.touched {
  border-color: red !important;
}
.spp-bundle-layout {
  &__get-set-cta {
    &.button--disabled {
      pointer-events: none;
    }
  }
  &__step {
    .bundle-product-slide {
      .shade-picker {
        &__outofstock {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (orientation: portrait) {
  .sign-in-panel {
    .panel-group {
      .new-account,
      #return-user {
        .adpl {
          .form-item {
            .return-user-email {
              width: 100%;
            }
            .label {
              line-height: 1.8em !important;
              margin-top: calc(-4em - 1px);
            }
          }
        }
      }
    }
  }
  #shipping-panel {
    .adpl {
      .form-item.delivery,
      .form-item.qas-submit-container {
        clear: both;
      }
      .select-address {
        .new-address {
          width: 431px !important;
          .form-item {
            label {
              margin-top: calc(-3em - 1px) !important;
              line-height: 1em;
            }
          }
        }
      }
      #qas {
        .form-item.building-name-number {
          input[type='text'] {
            width: 102%;
          }
        }
      }
      .gift-options {
        .sub-section {
          .gift-msg__head {
            .form-item {
              label {
                line-height: 1em;
                margin-top: calc(-3.5em - 1px);
              }
            }
          }
        }
      }
      .form-item.phone-number {
        label {
          margin-top: calc(-3.5em - 1px) !important;
          line-height: 3em !important;
        }
      }
      .form-item.default-shipping {
        padding: 20px 0 0 0 !important;
      }
    }
  }
  #review-panel {
    .adpl {
      .form-item {
        label {
          line-height: 1em;
          margin-top: calc(-3.5em - 1px) !important;
        }
      }
      .form-item.card-cvn {
        label {
          margin-top: calc(-3em - 1px) !important;
        }
      }
      .form-item.card-type,
      .form-item.card-expiry-date {
        .label-content {
          display: none;
        }
      }
      .gift-card-entry-container {
        margin: 10px 0 0 0;
        .form-item.gift-card-number,
        .form-item.gift-card-pin {
          label {
            line-height: 1em !important;
            margin-top: calc(-3.5em - 1px) !important;
          }
        }
      }
      .form-item.card-number {
        label {
          line-height: 1em;
          margin-top: calc(-3.5em - 1px) !important;
        }
      }
      .address-to-use-option {
        label {
          line-height: 1em;
          margin-top: calc(0em - 1px) !important;
        }
      }
    }
    .set-address {
      .adpl {
        .new-address {
          .form-item {
            label {
              line-height: 1em;
              margin-top: calc(-3.5em - 1px) !important;
            }
          }
          .form-item.title,
          .form-item.country-id-select {
            label {
              line-height: 3em;
              margin-top: calc(0em - 1px) !important;
            }
          }
          .form-item.phone-number {
            label {
              margin-top: calc(-3.5em - 1px) !important;
              line-height: 3em !important;
            }
          }
        }
      }
    }
  }
}

#review-panel,
#shipping-panel,
#registration-panel {
  .adpl {
    .sms-promo-wrapper {
      .form-item {
        .sms_input {
          width: 50%;
          margin: 20px 0px 0px 0px;
        }
        .sms_label {
          line-height: 3em;
          margin-top: calc(-3em - 1px);
          padding-left: 61px;
        }
      }
    }
  }
}

#confirmation-page {
  #registration-panel {
    .adpl {
      .create_account_content {
        .registration-form-container {
          .password {
            label {
              line-height: 3em !important;
              margin-top: calc(-3.5em - 1px);
            }
          }
        }
      }
    }
  }
}

.touch select {
  background-position: 95% 50% !important;
}

.no_img_resize img {
  height: auto;
  width: auto;
}

.lp_mobile {
  #lpChat {
    input[type='radio'] {
      position: absolute;
      left: -9999px;
    }
  }
}

@media only screen and (max-device-width: 320px) {
  .cart_overlay {
    .cart-confirm-wrapper {
      .cart-products {
        height: 220px;
      }
    }
  }
}

.cart_overlay {
  .cart-confirm-wrapper {
    .promos {
      .free_ship {
        height: 70px;
      }
    }
  }
}
/*  Power reviews */

.spp-mobile-page {
  .product {
    &.product--full {
      .rating_fav {
        .product__rating {
          @include breakpoint($small-down) {
            width: auto;
          }
        }
      }
    }
  }
}
/* Product Store Check */
.product-full__store-check {
  .instore-inventory-container {
    border-top: 1px solid $color-light-gray;
    .product-store-check--v2 {
      .product-store-check {
        &__inline--v2,
        &__overlay--container {
          display: block;
          .inline-errors {
            @include typography-label-text;
            color: $color-red;
            font-size: 14px;
          }
          .inline-container {
            border-bottom: none;
            border-top: none;
            padding: 0;
            &__controls {
              padding: 20px;
              .avail-info__msg--span {
                display: none;
              }
            }
            &__partner {
              @include typography-label-text;
              color: $color-core-black;
            }
            &__doors {
              .doors-container {
                .container-row__doors {
                  .door-row {
                    .door-info__avail {
                      .avail-info__msg {
                        width: 45%;
                        &--text {
                          font-size: 14px;
                          display: inline-block;
                          margin-#{$ldirection}: 0;
                          width: 80%;
                        }
                        &--span {
                          @include swap_direction(margin, 0.15em 0.25em 0 0);
                          border-radius: 50%;
                          display: inline-block;
                          height: 0.75em;
                          width: 0.75em;
                          padding: 0.2em;
                          &.msg-span {
                            &__avail {
                              background-color: $color-green;
                            }
                            &__not-avail {
                              background-color: $color-red;
                            }
                            &__call {
                              background-color: $color-orange;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &__overlay {
          .location-controls {
            &__close {
              color: inherit;
              &:before {
                content: '\E010';
              }
            }
            &__distance {
              #{$rdirection}: 22px;
            }
          }
          .overlay-container {
            .location-controls {
              &__distance--select {
                padding-#{$rdirection}: 27px;
                min-width: inherit;
                margin-#{$ldirection}: 12px;
              }
            }
            .doors-container {
              .container-row__doors {
                .door-row {
                  .door-info__avail {
                    .avail-info__msg {
                      width: 40%;
                      &--text {
                        font-size: 14px;
                      }
                      &--span {
                        display: inline-block;
                        position: inherit;
                      }
                    }
                  }
                  .door-info__address {
                    padding-top: 0;
                    width: 55%;
                  }
                }
              }
            }
          }
        }
      }
      .edit-controls {
        display: block;
      }
      .location-controls {
        padding-#{$ldirection}: 0;
        &.edit-controls {
          .location-controls__hdr {
            border-bottom: 1px solid $color-gray-op15;
            width: 100%;
            height: 30px;
          }
        }
        &__zip {
          margin-bottom: 10px;
          &--input {
            border: 1px solid $color-core-black;
            margin-#{$rdirection}: 5px;
            width: 60%;
          }
        }
        &__btn--zip {
          height: 100%;
          line-height: 20px;
          padding: 9px 30px;
        }
        &__info {
          @include typography-label-text;
          margin-#{$rdirection}: 0;
        }
        &__distance {
          #{$rdirection}: 10px;
        }
      }
    }
    .psc-state-init {
      .inline-container {
        &__partner {
          display: none;
        }
        &__link {
          display: block;
        }
      }
    }
    .find-in-store {
      display: inline-block;
      margin-bottom: 15px;
      cursor: pointer;
      font-size: 16px;
      font-weight: bold;
      width: 100%;
      text-align: center;
      border: 1px solid $color-black;
      padding: 5px 0;
      color: $color-black;
      text-decoration: none;
    }
  }
}

.product-foundation-vto {
  &__container {
    ul.shade-list-vto {
      .swatches--single {
        a.swatch {
          background-color: $color-white;
        }
      }
      &.perfect-shades {
        white-space: unset;
      }
      &.all-shades {
        height: 97px;
        margin-bottom: 0;
        white-space: inherit;
        span.vto-text {
          font-size: 12px;
        }
      }
    }
  }
  &__mobile-ctrl {
    .container {
      .product-foundation-vto {
        &__info-container {
          .product-name {
            font-size: 12px;
          }
          .current-shade {
            span {
              font-size: 12px;
            }
          }
        }
      }
    }
    &.active {
      padding-bottom: 2px !important;
    }
  }
}
// !important is used below to override the inline styles from 3rd party content
.spp-product-layout {
  .product-full {
    .afterpay-paragraph {
      .afterpay-link {
        @include swap_direction(padding, 2px 3px 0 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 1px solid #{$color-black};
        font-style: italic;
        font-weight: bold;
        text-align: center;
        font-size: 12px;
        color: #{$color-black} !important;
        line-height: 1;
        text-decoration: none !important;
      }
      .Clearpay-logo {
        vertical-align: sub !important;
      }
    }
    &__container {
      .product-full__data-main {
        .product-full__data-price-size-reviews {
          .product-full__installment-price {
            border: 0;
          }
        }
      }
    }
  }
  .product-full__container {
    .product-full__data-content--usage {
      a {
        color: $color-grey-scale;
      }
    }
  }
}
// !important is used below to override the inline styles from base files
.site-footer__wrap {
  .sticky-back-to-top-v2 {
    &.sticky-back-to-top-new {
      bottom: 120px !important;
    }
  }
  .sticky-footer-chat {
    &.sticky-footer-new-chat {
      .sticky-footer-chat__link {
        bottom: 185px !important;
        width: 75px !important;
      }
    }
  }
}

.registration-pro-page {
  .registration-pro-page__content {
    .registration-pro-page__required-container {
      top: 290px;
    }
  }
}
.field-elc-mpp-content {
  .content-block__content {
    .content-block__line--content {
      a {
        color: $color-grey-scale;
      }
    }
  }
}

//Clearpay Popup styles override
#afterpay-modal-overlay {
  #afterpay-modal-modal {
    width: 90%;
    height: 95%;
  }
}
