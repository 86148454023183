@media (max-width: 767px) {
  .spp-mobile-page {
    .product--full {
      .product__header {
        margin: 15px 0;
        padding-bottom: 15px;
        border-bottom: 1px solid #cccccc;
        .product__title,
        .product__price,
        .product__ppu {
          font-size: 12px;
          line-height: 15px;
          text-align: left;
          letter-spacing: 0.2em;
        }
        .product__title {
          max-width: 197px;
          margin: 0 0 0 15px;
          float: left;
        }
        .mobile-price {
          float: right;
          margin: 0 15px;
          .product__ppu {
            font-size: 8px;
          }
        }
      }
      .product__details {
        margin: 0 auto;
      }
      .product-gallery__photo--large {
        width: 200px;
      }
      .rating_fav {
        padding: 10px 0;
        border-top: 1px solid #cccccc;
        .product__rating {
          width: 180px;
          float: left;
          .BVRRRatingSummaryLinkWriteFirst,
          .BVRRDisplayContentNoReviews {
            text-align: left;
            padding-left: 5px;
          }
          .BVRROverallRatingContainer {
            padding-left: 18px;
          }
          .BVRRRatingSummaryLinkWriteFirst {
            a {
              display: inline;
              text-decoration: underline;
              color: #ff4661;
            }
          }
        }
        .favourite-heart {
          float: right;
          margin-right: 10px;
        }
      }
      .product__actions {
        padding: 0;
      }
      .product-palette {
        &__link-launch {
          margin-top: 0;
        }
        &__details-group-title {
          padding-bottom: 15px;
        }
      }
      .palette_wrapper {
        text-align: center;
      }
    }
    .non_shaded {
      margin: 0 auto;
      text-align: center;
      float: none !important;
    }
    .product-palette__details-group {
      padding: 0;
    }
    .responsive-tabs--how_to_use-wrap {
      margin: 0px auto;
    }
    .fluid-image {
      margin: 0 auto;
    }
    .product-gallery__shade {
      display: none;
    }
    .product-gallery__main {
      padding-left: 0;
    }
    .select_shade_cta {
      font-family: 'Brandon Text Bold';
      font-weight: normal;
      font-size: 19px;
      line-height: 27px;
      letter-spacing: 0.24em;
      text-transform: uppercase;
      font-size: 14px;
      color: #ff0000;
      cursor: pointer;
      border: 2px solid #ff0000;
      margin: 10px 0 15px;
      padding: 0 10px;
      display: inline-block;
      text-decoration: none;
    }
    .responsive-tabs--how_to_use-wrap {
      z-index: 0;
    }
    .slick-track {
      height: 200px;
    }
    .product-gallery .slick-next {
      right: -27px;
    }
    .product-gallery .slick-prev {
      left: -20px;
    }
    .shaded_details {
      background: #fff;
      border-bottom: 1px solid #cccccc;
      z-index: 1;
      text-align: center;
      position: relative;
      .product-palette {
        border-top: 1px solid #d4d4d4;
        margin-top: 25px;
        padding-top: 10px;
        position: static;
      }
      .product-palette:before {
        top: 39px;
        z-index: 10;
      }
      .js-product-gallery__main {
        float: left;
        width: 45%;
        margin-top: 10px;
      }
      .slick-wrapper {
        width: 222px;
        margin-left: 20px;
      }
      .slick-track {
        height: 100px;
      }
      .product-gallery {
        &__photo--large {
          width: 100px;
        }
        .slick-next,
        .slick-prev {
          display: none !important;
        }
      }
      .select-cta {
        float: right;
        width: 48%;
        .mobile-price {
          text-align: left;
        }
        .product {
          &__ppu,
          &__price {
            font-size: 10px;
            line-height: 10px;
            margin: 10px 0;
          }
          &__ppu {
            font-size: 8px;
            float: right;
          }
          &__price {
            font-weight: bold;
            float: left;
          }
          &__notify-me {
            display: block;
            .product__button {
              width: 100%;
            }
          }
        }
        .shade-picker {
          &__color-name {
            select {
              border-top: 2px solid #cccccc;
              border-bottom: 2px solid #cccccc;
              background-position: center right;
            }
          }
        }
      }
    }
    .hide_select {
      color: #d4d4d4;
      margin: 0 auto;
      border: 0;
      .select_shade_text {
        border-bottom: 2px solid #d4d4d4;
      }
      .product-gallery__main {
        margin-top: 10px;
      }
    }
    .hide_select_grey {
      color: #d4d4d4;
      border-bottom: 2px solid #d4d4d4 !important;
    }
    .slick-wrapper {
      width: 200px;
      margin: 0 auto;
    }
    .hide {
      display: none;
    }
    .slider {
      width: 50%;
      margin: 100px auto;
    }
    .slick-slide img {
      width: 100%;
    }
    .slick-prev:before,
    .slick-next:before {
      color: black;
    }
  }
}
