@media (max-width: 767px) {
  .site-utils {
    &__item {
      &--hamburger {
        top: 13px;
      }
      &--stores,
      &--search,
      &--cart {
        position: absolute;
        top: 13px;
      }
      &--search {
        order: 1;
        -webkit-order: 1;
        left: 50px;
        .site-utils__dropdown__arrow {
          top: 44px;
          left: 45px;
        }
      }
      &--stores {
        right: 40px;
      }
    }
    &__icon {
      &--search {
        margin-left: 2px;
        top: -1px;
        font-size: 15px;
      }
    }
    &__link-text {
      display: none;
    }
    &__links {
      height: 0;
    }
    &__logo-icon {
      font-size: 17px;
    }
  }
  .js-sticky-header {
    .js-site-header-sticky {
      .site-utils__item {
        &--stores,
        &--search {
          display: inline-block;
          top: 0px;
        }
        &--search {
          left: 50px;
        }
        &--stores {
          right: 40px;
        }
        &--hamburger {
          left: 8px;
        }
      }
    }
    .site-utils__dropdown__arrow {
      top: 44px;
      left: 45px;
    }
  }
  .header-search {
    top: 54px;
  }
  .section-giftcards .site-utils__links {
    height: auto !important;
  }
}
